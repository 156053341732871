<script setup lang="ts">
const { getResourceUrl } = useResources()

// TODO get all pages
const { data: categories } = await useLazyAsyncData(() =>
  createProductsRepository().getAllCategories()
)

const localePath = useLocalePath()
</script>

<template>
  <section class="py-16">
    <div class="container">
      <div class="mx-auto max-w-4xl">
        <ContentBlock
          content-code="hp-intro"
          text-container-class="content-block-intro mb-24 font-bold leading-tight text-xl font-pragmatica-ext"
          :show-title="false"
        />
        <ul>
          <li
            v-for="category in categories?.items"
            :key="category.nid"
            class="flex"
          >
            <div class="flex pb-8">
              <img
                :src="getResourceUrl(category.image?.url)"
                height="55"
                width="55"
                class="-mt-2 pr-2 lg:-mt-4 lg:pr-4"
                alt="Category icon"
                loading="lazy"
              />
              <NuxtLink
                :to="
                  localePath({
                    name: 'product-lines-category-slug',
                    params: { slug: category.urlSlug },
                  })
                "
                class="font-bold no-underline transition duration-300"
              >
                <h1
                  class="font-pragmatica-ext group text-2xl font-bold md:text-4xl"
                >
                  <span>{{ category.title?.[0] }}</span>
                  <span
                    class="inline-block transition-transform duration-300 group-hover:translate-y-1.5"
                  >
                    {{ category.title?.[1] }}
                  </span>
                  <span>
                    {{
                      category.title?.length > 2
                        ? category.title?.substring(2)
                        : ''
                    }}
                  </span>
                </h1>
              </NuxtLink>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="sass">
.content-block-intro
  p
    @apply mb-4

  .lead
    @apply whitespace-normal text-xl font-bold leading-[1.3]

  .font-extended
    font-family: 'Pragmatica Ext', sans-serif
</style>
