<script setup lang="ts">
const { getResourceUrl } = useResources()

const { locale } = useI18n()
const heroImageRef = ref()

const { data: slidesData } = await useLazyAsyncData(() =>
  createCarouselSlidesRepository().getAll()
)

const slides = computed(() => slidesData.value?.items ?? [])

// const imagesToPreload = computed(() =>
//   slides.value
//     .map((slide) => {
//       if (slide && slide.image) {
//         return {
//           rel: 'preload',
//           as: 'image',
//           href: getResourceUrl(slide.image.url) + '/10x10',
//         }
//       }
//     })
//     .filter((val): val is { rel: string; as: string; href: string } => !!val)
// )

// useHead({
//   link: imagesToPreload.value,
// })
</script>

<template>
  <section
    class="section relative mb-5 bg-neutral-50 py-0"
    data-test="front-node-carousel-default-content"
  >
    <UiCarousel
      :opts="{
        loop: true,
      }"
      class="min-h-[70svh] w-full bg-neutral-100 @container"
    >
      <UiCarouselPrevious class="absolute left-2 top-1/2 z-10 text-white">
        <Icon size="30" name="bi:arrow-left" />
      </UiCarouselPrevious>
      <UiCarouselContent class="h-full">
        <UiCarouselItem
          v-for="(slide, index) in slides"
          :key="slide.nid"
          class="@4xl:min-w-[calc(100vw-250px)] @5xl:container @5xl:min-w-0 @4xl:px-2"
        >
          <div class="flex flex-col align-middle">
            <div class="relative">
              <div
                class="pointer-events-none absolute z-10 mb-0 flex h-full w-full flex-col justify-center"
              >
                <div class="container">
                  <div class="pointer-events-auto inline-block">
                    <h1
                      class="font-pragmatica-ext group mb-0 text-5xl font-bold text-white drop-shadow-2xl md:text-8xl"
                    >
                      <NuxtLink
                        :to="locale + '/' + slide.buttonLink"
                        class="intro-carousel__title intro-carousel__title--light brand-decor no-underline"
                      >
                        <span>{{ slide.title?.[0] }}</span>
                        <span
                          class="inline-flex transition-transform duration-300 group-hover:translate-y-3 lg:group-hover:translate-y-5"
                        >
                          {{ slide.title?.[1] }}
                        </span>
                        <span>
                          {{
                            slide.title?.length > 2
                              ? slide.title?.substring(2)
                              : ''
                          }}
                        </span>
                      </NuxtLink>
                    </h1>
                  </div>
                </div>
              </div>
              <div
                class="h-[520px] max-h-[calc(100svh-9rem)] w-full overflow-hidden xl:h-[820px]"
              >
                <NuxtImg
                  v-if="slide.image?.fileType?.startsWith('image')"
                  height="100px"
                  width="100px"
                  ref="heroImageRef"
                  class="h-full w-full object-cover object-bottom transition-all duration-300"
                  :src="getResourceUrl(slide.image.url)"
                  sizes="400px sm:700px md:850px lg:1000px xl:1500px"
                  :alt="slide.image?.metaAlt || slide.title || 'Hero image'"
                  :title="slide.image?.metaTitle"
                  :loading="index === 0 ? 'eager' : 'lazy'"
                  :preload="index === 0"
                />
                <video
                  v-else
                  ref="heroImageRef"
                  :src="getResourceUrl(slide.image.url)"
                  autoplay
                  loop
                  muted
                  class="aspect-auto h-full w-full object-cover"
                ></video>
              </div>
            </div>
            <div class="container py-10 sm:py-8">
              <div
                class="mx-auto flex max-w-6xl gap-4 text-lg leading-tight lg:text-xl"
              >
                <div
                  class="font-pragmatica-ext shrink whitespace-normal"
                  v-html="slide.content"
                />
                <div class="my-auto shrink-0 max-lg:hidden">
                  <NuxtLink
                    :to="locale + '/' + slide.buttonLink"
                    class="whitespace-nowrap border-2 border-black px-5 py-2 text-xl font-bold no-underline hover:bg-black hover:text-white"
                  >
                    {{ slide.buttonText }}
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
        </UiCarouselItem>
      </UiCarouselContent>
      <UiCarouselNext class="absolute right-2 top-1/2 z-10 text-white">
        <Icon size="30" name="bi:arrow-right" />
      </UiCarouselNext>
    </UiCarousel>
  </section>
</template>
