<script setup lang="ts">
const { t } = useI18n()

useSeoMeta({
  title: t('pages.index.meta.title'),
  description: t('pages.index.meta.description'),
})
</script>

<template>
  <main>
    <HomepageHero />
    <HomepageProducts />
    <HomepageNews :title="$t('base.news.title')" :is-home-page="true" />
    <LazyNewsSubscribe />
    <LazyHomepageReferences />
    <LazyHomepageRegister />
    <ContentBlock
      content-code="hp-about-us"
      section-class="py-16"
      container-class="container xl:max-w-7xl"
      text-container-class="mb-14 pr-10 md:w-3/4 lg:w-1/2"
    />
  </main>
</template>
